import { Component, OnInit } from '@angular/core';
import { HomeHeaderService } from '../shared/home-header.service';
import $ from "../../../node_modules/jquery/dist/jquery";
import { ContactusService } from '../shared/contactus.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor( private service :HomeHeaderService ,public fservice : ContactusService) {
    this.fservice.GetRecords();
   }

  ngOnInit() {

    this.service.GetRecords();

    //alert(this.service.home_header_list[0].contactNo)

    $(document).on("scroll", function(){
      if
        ($(document).scrollTop() > 86){
        $("#banner").addClass("shrink");
      }
      else
      {
        $("#banner").removeClass("shrink");
      }
    });


  }




}
