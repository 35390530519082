import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestimonialService {
  readonly rootURL = environment.rootURL;
  public testimonial_list: any[];
  public testimonial_active_list: any[];
  public service_imgPath:string='no data uploaded';
  constructor(private fb: FormBuilder, private http: HttpClient) { }


  form: FormGroup = this.fb.group({
    TestimonialID: [null],
    TestimonialContent: ['', Validators.required],
    FirstName: ['', Validators.required],
    LastName: ['', Validators.required],
    Text_One: ['', Validators.required],
    Text_Two: ['', Validators.required],
   // ImgPath: [''],
    IsActive: [true],
  })


  GetRecords() {

    return this.http.get<any>(this.rootURL + 'testimonial/read-api.php').subscribe((response: any) => {
      this.testimonial_list = response;

      console.log(this.testimonial_list);
      
    })
  }

  Get_ActiveRecords() {

    return this.http.get<any>(this.rootURL + 'testimonial/read-api.php').subscribe((response: any) => {
      this.testimonial_active_list = response;

      console.log(this.testimonial_active_list);

     
      
    })
  }

  SaveRecors(imgpath:any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      // TestimonialID:this.form.controls.TestimonialID.value,
      TestimonialContent: this.form.controls.TestimonialContent.value,
      FirstName: this.form.controls.FirstName.value,
      LastName: this.form.controls.LastName.value,
      Text_One: this.form.controls.Text_One.value,
      Text_Two: this.form.controls.Text_Two.value,
      ImgPath: imgpath,
      IsActive: this.form.controls.IsActive.value
    }
    let data = JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'testimonial/create-api.php', data).pipe(catchError(this.handleError))
  }



  UpdateRecords(imgpath:any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      TestimonialID: this.form.controls.TestimonialID.value,
      TestimonialContent: this.form.controls.TestimonialContent.value,
      FirstName: this.form.controls.FirstName.value,
      LastName: this.form.controls.LastName.value,
      Text_One: this.form.controls.Text_One.value,
      Text_Two: this.form.controls.Text_Two.value,
      ImgPath: imgpath,
      IsActive: this.form.controls.IsActive.value
    }
    let data = JSON.stringify(body);
    return this.http.put<any>(this.rootURL + 'testimonial/update-api.php' , data ).pipe(catchError(this.handleError))
  }

  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }

  DeleteRecords(id:any) {

    var body={
      TestimonialID:id
    }
    let data = JSON.stringify(body);
    return this.http.put<any>(this.rootURL + 'testimonial/delete-api.php',data).pipe(catchError(this.handleError));
      
    
  }
}
