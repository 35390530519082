import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminServiceService } from 'src/app/shared/admin-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-service-add',
  templateUrl: './admin-service-add.component.html',
  styleUrls: ['./admin-service-add.component.css']
})
export class AdminServiceAddComponent implements OnInit {
  public fileToUpload: any;
  readonly rootURL = environment.rootURL;
  public readonly _imgPath = environment.imgPath;
  public progress: number;
  public message: string;
  button = 'Submit';
  	isLoading = false;
  @Output() public onUploadFinished = new EventEmitter();
  constructor(private service:AdminServiceService,private http: HttpClient, private toastr: ToastrService, private router: Router, public dialogRef: MatDialogRef<AdminServiceAddComponent>) { }

  ngOnInit() {
  }

  imgpath: string;


  public uploadFile = (files) => {


    if (files.length === 0) {
      return;
    }

    this.fileToUpload = <File>files[0];
    console.log(this.fileToUpload);

  }


  onSubmit() {
    this.isLoading = true;
    this.button = 'Processing';
    let idInput = (<HTMLInputElement>document.getElementById('file')).files;
    if (idInput.length === 0) {
      return this.SaveData(this.service.service_imgPath);
    }
    else {
      const formData = new FormData();
      formData.append('file', this.fileToUpload, this.fileToUpload.name);
      this.http.post(this.rootURL + 'uploads/file-upload.php', formData, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response) {
            this.onUploadFinished.emit(event.body);
          
            this.SaveData(event.body);
          }
        });

    }


  } 


  SaveData(imgpath) {
    if (this.service.form.controls.ServiceId.value == null) {
      this.service.SaveRecors(imgpath).subscribe((result) => {
        if (result > 0) {
          this.toastr.success('Record Save Successfully..', 'success..!');
          this.isLoading = false;
        this.button = 'Submit';
          this.onClear();
        }
      })

    } else {
      this.service.UpdateRecords(imgpath).subscribe((result) => {
        if (result > 0) {
          this.toastr.success('Record Updated Successfully..', 'updated..!');
          this.isLoading = false;
        this.button = 'Submit';
          this.onClear();
        }
      })
    }

  }

  onClear() {
    this.service.form.reset();
    this.dialogRef.close();
    this.service.Get_service_mastet();
    this.service.service_imgPath = 'no data';
  }

  
}
