import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BlogService } from 'src/app/shared/blog.service';
import { BlogAddComponent } from '../blog-add/blog-add.component';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {

  constructor(private service :BlogService,private dialog: MatDialog,private toastr: ToastrService) { }

  ngOnInit() {

    this.service.GetRecords();
  }


  editrecords(_blog: any) {
    this.ShowPopUp();

    this.service.service_imgPath=_blog.imgPath;
    this.service.form.patchValue({

      BlogPostID:_blog.blogPostID ,
      BlogContent:_blog.blogContent ,
    FirstName: _blog.firstName,
    LastName:_blog.lastName ,
    BlogAddress:_blog.blogAddress ,
    IsActive:_blog.isActive ,
    })
  }

  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "700px";
    this.dialog.open(BlogAddComponent, diallogConfig)

  }


  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.service.DeleteRecords(id).subscribe(res => {
        this.service.GetRecords();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  }

}
