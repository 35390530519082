import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContactusService {
  readonly rootURL = environment.rootURL;
  public list=[];
  public _inqlist=[];
  public imgPath:string;
  constructor(public fb: FormBuilder, public http: HttpClient) { } 


  form: FormGroup = this.fb.group({
    contactus_id : [null],
    email: ['', Validators.required],
    address: ['', Validators.required],
    contact_1: ['', Validators.required],
    conatct_2: ['', Validators.required],
    imgPath:[''],
    content:[''],
  })


  GetRecords() {

    return this.http.get<any>(this.rootURL + 'contactus/read-api.php').subscribe((response: any) => {
      console.log(response);
      
      
      this.list = response;

     
      
    })
  }


  SaveRecors(imgpath) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      // TestimonialID:this.form.controls.TestimonialID.value,
      email: this.form.controls.email.value,
      address: this.form.controls.address.value,
      contact_1: this.form.controls.contact_1.value,
      conatct_2: this.form.controls.conatct_2.value,
      imgPath: imgpath,
      content: this.form.controls.content.value,
    }
    let data = JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'contactus/create-api.php', data).pipe(catchError(this.handleError))
  }


  UpdateRecords(imgpath) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      contactus_id: this.form.controls.contactus_id.value,
      email: this.form.controls.email.value,
      address: this.form.controls.address.value,
      contact_1: this.form.controls.contact_1.value,
      conatct_2: this.form.controls.conatct_2.value,
      imgPath: imgpath,
      content: this.form.controls.content.value,
    }
    let data = JSON.stringify(body);

    console.log(data);
    
    return this.http.put<any>(this.rootURL + 'contactus/update-api.php' ,data).pipe(catchError(this.handleError))
  }

  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }

  DeleteRecords(id:number) {

    var body ={
      contactus_id:id
    }

    let data =JSON.stringify(body);
    return this.http.put<any>(this.rootURL + 'contactus/delete-api.php',data);
      
    
  }


  SaveInquiry(body) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
   
    let data = JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'inquiry/create-api.php', data).pipe(catchError(this.handleError))
  }


  GetInquiryRecords() {

    return this.http.get<any>(this.rootURL + 'inquiry/read-api.php').subscribe((response: any) => {
      this._inqlist = response;
    })
  }

}
