import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { parse } from 'querystring';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  readonly rootURL = environment.rootURL;
  public Blog_list: any[];
  public Blog_active_list: any[];
  public service_imgPath:string='no data uploaded';
  public list=[];
  public id_list=[];
 
  constructor(private fb: FormBuilder, private http: HttpClient) { }


  form: FormGroup = this.fb.group({
    BlogPostID : [null],
    BlogContent: ['',],
    FirstName: ['', Validators.required],
    LastName: ['', Validators.required],
    BlogAddress: ['', Validators.required],
   // ImgPath: [''],
    IsActive: [true],
  })


  GetRecords() { 

    return this.http.get<any>(this.rootURL + 'blogpost/read-api.php').subscribe((response: any) => {
      console.log(response);
      this.list=response;
      this.Blog_list = response;

      
      
    })
  }


  Get_ActiveRecords() {

    return this.http.get<any>(this.rootURL + 'blogpost/read-api.php').subscribe((response: any) => {
      this.Blog_active_list = response;

      console.log(this.Blog_active_list);
      
    })
  }


  SaveRecors(imgpath:any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      // TestimonialID:this.form.controls.TestimonialID.value,
      BlogContent: this.form.controls.BlogContent.value,
      FirstName: this.form.controls.FirstName.value,
      LastName: this.form.controls.LastName.value,
      BlogAddress: this.form.controls.BlogAddress.value,
      ImgPath: imgpath,
      IsActive: this.form.controls.IsActive.value
    }
    let data = JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'blogpost/create-api.php', data).pipe(catchError(this.handleError))
  }

  UpdateRecords(imgpath:any) {

   
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      BlogPostID: this.form.controls.BlogPostID.value,
      BlogContent: this.form.controls.BlogContent.value,
      FirstName: this.form.controls.FirstName.value,
      LastName: this.form.controls.LastName.value,
      BlogAddress: this.form.controls.BlogAddress.value,
      ImgPath: imgpath,
      IsActive: this.form.controls.IsActive.value
    }
    let data = JSON.stringify(body);

    console.log(data);
    
    return this.http.put<any>(this.rootURL + 'blogpost/update-api.php',  data, ).pipe(catchError(this.handleError))
  }

  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }


  DeleteRecords(id:any) {
    var body ={
      BlogPostID:id
    };

    let data=JSON.stringify(body);

    return this.http.put<any>(this.rootURL + 'blogpost/delete-api.php',data);
      
    
  }


  GetById(id:any) {
    var body ={
      blogPostID:id
    };

    let data=JSON.stringify(body);
  console.log(data);
  
    return this.http.put<any>(this.rootURL + 'blogpost/blog-list.php',data).subscribe((result)=>{

      this.id_list=result;

     

      console.log(this.id_list);
      

    });
      
    
  }

}
