import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;
  button = 'Submit';
  isLoading = false;
  constructor( private router:Router, private service:LoginService) { }

  ngOnInit() {

    
  }
  get f() { return this.service.form.controls; }

  OnSubmit(){

    this.submitted = true;

    if (this.service.form.invalid) {
      return;
    }
    this.isLoading = true;
    this.button = 'Processing';

    this.service.Login().subscribe((res:any)=>{

     

      localStorage.setItem('UserId',res);
      
      if (res > 0)  {

 this.router.navigateByUrl('/admin');
 this.button = 'Submit';
      }

      else{
         
        alert('Login Failed')
        this.button = 'Submit';
        this.service.form.reset();
        this.submitted = false;
      }
    })
   
  }
}
