import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-contactus',
  templateUrl: './admin-contactus.component.html',
  styleUrls: ['./admin-contactus.component.css']
})
export class AdminContactusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
