import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ContactusService } from 'src/app/shared/contactus.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-contactus',
  templateUrl: './add-contactus.component.html',
  styleUrls: ['./add-contactus.component.css']
})
export class AddContactusComponent implements OnInit {
  button = 'Submit';
  isLoading = false;
  public fileToUpload: any;
  readonly rootURL = environment.rootURL;
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();
  constructor(private service: ContactusService,private toastr: ToastrService, private router: Router, public dialogRef: MatDialogRef<AddContactusComponent>,private http: HttpClient) { }

  ngOnInit() {
  } 


  public uploadFile = (files) => {
  
    
    if (files.length === 0) {
      return;
    }
  
     this.fileToUpload = <File>files[0];
    console.log(this.fileToUpload);
    
  }

  
  onSubmit() {
    this.isLoading = true;
    this.button = 'Processing';

    let idInput = (<HTMLInputElement>document.getElementById('file')).files;

    if(idInput.length===0){
      return this.SaveData(this.service.form.controls.imgPath.value);
    }
    
   else{
    const formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
  this.http.post(this.rootURL + 'uploads/file-upload.php', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.onUploadFinished.emit(event.body);
         
          this.SaveData(event.body);
        }
      });
   }

  }


  SaveData(imgpath){
    if (this.service.form.controls.contactus_id.value == null) {
      this.service.SaveRecors(imgpath).subscribe((result) => {
        if (result > 0) {
          this.toastr.success('Record Save Successfully..', 'success..!');
          this.isLoading = false;
          this.button = 'Submit';
          this.onClear();
        }
      })

    } else {
      this.service.UpdateRecords(imgpath).subscribe((result) => {

        console.log(result);
        
        if (result > 0) {
          this.toastr.success('Record Updated Successfully..', 'updated..!');
          this.isLoading = false;
          this.button = 'Submit';
          this.onClear();
        }
      })
    }

  }

  onClear() {
    this.service.form.reset();
    this.dialogRef.close();
    this.service.GetRecords();
   }
  

}
