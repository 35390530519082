import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { AboutusDetailsComponent } from './aboutus-details/aboutus-details.component';
import { AboutusComponent } from './admin/aboutus/aboutus.component';
import { AddAboutusComponent } from './admin/add-aboutus/add-aboutus.component';
import { AdminContactusComponent } from './admin/admin-contactus/admin-contactus.component';
import { AdminServiceComponent } from './admin/admin-service/admin-service.component';
import { BlogComponent } from './admin/blog/blog.component';

import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { HomeHeaderComponent } from './admin/home-header/home-header.component';
import { HomeSliderComponent } from './admin/home-slider/home-slider.component';
import { IndustryComponent } from './admin/industry/industry.component';
import { InquiryComponent } from './admin/inquiry/inquiry.component';
import { LoginComponent } from './admin/login/login.component';
import { ResumeComponent } from './admin/resume/resume.component';
import { TestimonialComponent } from './admin/testimonial/testimonial.component';
import { AdminWhyusComponent } from './admin/whyus/admin-whyus/admin-whyus.component';
import { ArticalComponent } from './artical/artical.component';
import { AuthGuard } from './auth.guard';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { IndustryServicesComponent } from './industry-services/industry-services.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { OurservicesDetailsComponent } from './ourservices-details/ourservices-details.component';
import { PostResumeComponent } from './post-resume/post-resume.component';

const routes: Routes = [

  {
    path: '', component: HomeComponent
  },
  {
    path: 'view-all', component: IndustryServicesComponent
  },
  {
    path: 'post-resume', component: PostResumeComponent
  },
  {
    path: 'home', redirectTo: ''
  },
  {
    path: 'AboutUs', component: AboutusDetailsComponent
  },
  {
    path: 'OurServices', component: OurservicesDetailsComponent
  },
  {
    path: 'DropResume', component: PostResumeComponent
  },
  {
    path: 'ContactUs', component: ContactUsComponent
  }
  ,{
  path:'our-industry', component:IndustryServicesComponent}
  ,{
    path:'our-industry/:type', component:IndustryServicesComponent}
  ,
  {
    path:'Blog', component:BlogDetailsComponent}
  ,
  {
    path:'artical',component:ArticalComponent
  }, {
    path: 'admin', component: DashboardComponent , canActivate:[AuthGuard],
    children: [
      {
        path: 'about-us', component: AboutusComponent
      },
      {
        path: 'add-about-us', component: AddAboutusComponent
      },
      {
        path: 'why-us', component: AdminWhyusComponent
      },
      {
        path: 'resume-list', component: ResumeComponent
      },
      {
        path: 'testimonial', component: TestimonialComponent
      },
      {
        path: 'blog', component: BlogComponent
      },
      {
        path: 'header', component: HomeHeaderComponent
      },
      {
        path: 'admin-industry', component: IndustryComponent
      },
      {
        path: 'admin-service', component: AdminServiceComponent
      },
      {
        path: 'admin-contactus', component: AdminContactusComponent
      },
      {
        path: 'admin-slider', component: HomeSliderComponent
      },
      {
        path: 'admin-inquiry', component: InquiryComponent
      },
    ]
  },
  {
    path:'crm_admin',component:LoginComponent
  }
  ,{
    path:'**',component:NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
