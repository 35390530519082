import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { AboutUs } from './myweb.modules';
@Injectable({
  providedIn: 'root'
})
export class AboutusserviceService {
  about_list: Observable<AboutUs[]>;
  readonly rootURL = environment.rootURL;
  constructor(private http: HttpClient, private fb: FormBuilder) { }


  form: FormGroup = this.fb.group({
    $key: [null],
    about_content: ['', Validators.required],
    isActive: ['', Validators.required],
  });

  // form: FormGroup = new FormGroup({
  //   // $key: new FormControl(null),
  //   about_content: new FormControl('', Validators.required),
  //   isActive: new FormControl(false)
  // });

  // initializeFormGroup() {
  //   this.form.setValue({
  //     // $key: null,
  //     about_content: '',
  //     isActive: false
  //   });
  // }


  about_content_id: number = 0;
  about_content_modal: any;
  isActive_modal: any = true;


  SaveRecords() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      about_content: this.form.value.about_content,
      is_Active: this.form.value.isActive,
    }
    let data = JSON.stringify(body);
    console.log(data);

    // return this.http.post<any>(this.rootURL + 'about/create-api.php', data).pipe(catchError(this.handleError))
    return this.http.post<any>(this.rootURL + 'about/create-api.php', data);
  }

  UpdateRecords() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      aboutus_Id: this.form.value.$key,
      about_content: this.form.value.about_content,
      is_Active: this.form.value.isActive,
    }
    let data = JSON.stringify(body);
    console.log(data);

    return this.http.post<any>(this.rootURL + 'about/update-api.php', data).pipe(catchError(this.handleError))
  }


  GetAboutUsActiveData() {

    return this.http.get(this.rootURL + 'about/read-api.php').subscribe((result:any)=>{
      console.log(result);
      this.about_list=result;
    });
  }

  GetAboutUsAllData() {
    return this.http.get(this.rootURL + 'about/read-api.php').subscribe((result:any)=>{
      console.log(result);
      this.about_list=result;
    });
  } 


  GetAboutUsDataById(id, contents, avtive) {

    this.form.patchValue({
      $key: id,
      about_content: contents,
      isActive: avtive
    });

    //  this.about_content_id= aboutus.about_content_id;
    //  this.about_content_modal= aboutus.about_content;
    //  this.isActive_modal=aboutus.is_Active;

  }



  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }



  SaveImgPath(imgpath: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      AboutUsImageId: 0,
      AboutUsId: 0,
      ImgPath: imgpath,
      IsActive: true
    }
    let data = JSON.stringify(body);

    return this.http.post<any>(this.rootURL + 'aboutimage/create-api.php', data).pipe(catchError(this.handleError))

  }

  GetAboutUsImage() {

    return this.http.get(this.rootURL + 'aboutimage/read-api.php');
  }



  UpdateAboutUsImage(imgpath: string, AboutUsImageId: number) {

    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      AboutUsImageId: AboutUsImageId,
      AboutUsId: 0,
      ImgPath: imgpath,
      IsActive: true
    }
    let data = JSON.stringify(body);

    console.log(data
    );

    return this.http.put<any>(this.rootURL + 'aboutimage/update-api.php' , data).pipe(catchError(this.handleError))
  }


  
  DeleteRecords(id:number) {

    var body ={
      Aboutus_Id:id
    }

    let data =JSON.stringify(body);
    return this.http.put<any>(this.rootURL + 'about/delete-api.php',data);
      
    
  }
}


