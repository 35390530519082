import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { HomeHeaderService } from 'src/app/shared/home-header.service';
import { environment } from 'src/environments/environment';
import { HomeHeaderAddComponent } from '../home-header-add/home-header-add.component';

@Component({
  selector: 'app-home-header-list',
  templateUrl: './home-header-list.component.html',
  styleUrls: ['./home-header-list.component.css']
})
export class HomeHeaderListComponent implements OnInit {
  
  constructor( private service : HomeHeaderService,private dialog: MatDialog,private toastr: ToastrService) { }

  ngOnInit() {
    
    this.service.GetRecords();
  }

  editrecords(_head: any) {
    this.ShowPopUp();


    
    this.service.service_imgPath=_head.logo_ImgPath;
    this.service.form.patchValue({

      Home_HeaderId:_head.home_HeaderId ,
      ContactNo:_head.contactNo ,
      EmailId: _head.emailId,
      TimeSlot:_head.timeSlot ,
      Days_Name:_head.days_Name ,
   
    })
  }

  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "700px";
    this.dialog.open(HomeHeaderAddComponent, diallogConfig)

  }
  
  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.service.DeleteRecords(id).subscribe(res => {
        this.service.GetRecords();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  }
}
