import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery' ;
@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  constructor( public router :Router) { }

  ngOnInit() {
  }
  OnCellclick (event): void
  {
    $("#wrapper").toggleClass("toggled");
  } 


  logout(){


    

    localStorage.removeItem('UserId');
this.router.navigateByUrl('/crm_admin')
  }
} 
