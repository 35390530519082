import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminServiceDetailService } from '../shared/admin-service-detail.service';
import * as $ from 'jquery'
@Component({
  selector: 'app-ourservices-details',
  templateUrl: './ourservices-details.component.html',
  styleUrls: ['./ourservices-details.component.css']
})
export class OurservicesDetailsComponent implements OnInit {
  readonly rootURL = environment.rootURL;
  panelOpenState = false;
  public list:any [];




  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.Get_service_master()
  }

  Get_service_master() {
    return this.http.get<any>(this.rootURL + 'services/service-list.php').subscribe((response: any) => {
      
      this.list=response;

      console.log(this.list);
      

      
    })
  }





}
