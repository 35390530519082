import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminServiceDetailService } from 'src/app/shared/admin-service-detail.service';
import { AdminServiceService } from 'src/app/shared/admin-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-service-detail-add',
  templateUrl: './admin-service-detail-add.component.html',
  styleUrls: ['./admin-service-detail-add.component.css']
})
export class AdminServiceDetailAddComponent implements OnInit {
  public fileToUpload: any;
  readonly rootURL = environment.rootURL;
  public readonly _imgPath = environment.imgPath;
  public progress: number;
  public message: string;
  button = 'Submit';
  	isLoading = false;
  @Output() public onUploadFinished = new EventEmitter();
  constructor(private service_master :AdminServiceService,private service :AdminServiceDetailService,private http: HttpClient, private toastr: ToastrService, private router: Router, public dialogRef: MatDialogRef<AdminServiceDetailAddComponent>) { }

  ngOnInit() {
   
    this.service_master.Get_service_mastet();
  }



  onSubmit() {
    this.isLoading = true;
    this.button = 'Processing';
    //console.log(this.service.form);

    
    
    if (this.service.form.controls.ServiceDetailId.value == null) {
      this.service.SaveRecors().subscribe((result) => {
        if (result > 0) {
          this.toastr.success('Record Save Successfully..', 'success..!');
          this.isLoading = false;
        this.button = 'Submit';
          
          this.onClear();
        }
      })

    } else {
      this.service.UpdateRecords().subscribe((result) => {
        if (result > 0) {
          this.toastr.success('Record Updated Successfully..', 'updated..!');
          this.isLoading = false;
          this.button = 'Submit';
          this.onClear();
        }
      })
    }

  }
  onClear() {
    this.service.form.reset();
    this.dialogRef.close();
    this.service.Get_service_Details_master();
    this.service_master.Get_service_mastet();
   
  }
}
