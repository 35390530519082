import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { Country, Why_Us } from './myweb.modules';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {
  private why_list:Observable<Why_Us[]>;
  public country_list:Observable<Country>
  readonly rootURL = environment.rootURL;
  public month =[{month:"1 Month"}, {month:"2 Month"},{month:"3 Month"},{month:"4 Month"},{month:"5 Month"},{month:"6 Month"},{month:"7 Month"},{month:"8 Month"},{month:"9 Month"},{month:"10 Month"},{month:"11 Month"},{month:"12 Month"}];
  public exp_years=[{ Years:"Fresher"},{ Years:"1 Years"},{ Years:"2 Years"},{ Years:"3 Years"},{ Years:"4 Years"},{ Years:"5 Years"},{ Years:"6 Years"},{ Years:"7 Years"},{ Years:"8 Years"},{ Years:"9 Years"},{ Years:"10 Years"},{ Years:"11 Years"},{ Years:"12 Years"},{ Years:"13 Years"},{ Years:"14 Years"},{ Years:"15 Years"},{ Years:"16 Years"},{ Years:"17 Years"},{ Years:"18 Years"},{ Years:"19 Years"},{ Years:"20 Years"},{ Years:"21 Years"},{ Years:"22 Years"},{ Years:"23 Years"},{ Years:"24 Years"},{ Years:"25 Years"},{ Years:"26 Years"},{ Years:"27 Years"},{ Years:"28 Years"},{ Years:"29 Years"},{ Years:"30 Years"}]
  public sal_lac=[{lac:1},{lac:2},{lac:3},{lac:4},{lac:5},{lac:6},{lac:7},{lac:8},{lac:9},{lac:10},{lac:11},{lac:12},{lac:13},{lac:14},{lac:15},{lac:16},{lac:17},{lac:18},{lac:19},{lac:20},{lac:21},{lac:22},{lac:23},{lac:24},{lac:25},{lac:26},{lac:27},{lac:28},{lac:29},{lac:30},{lac:31},{lac:32},{lac:33},{lac:34},{lac:35},{lac:36},{lac:37},{lac:38},{lac:39},{lac:40},{lac:41},{lac:42},{lac:43},{lac:44},{lac:45},{lac:46},{lac:47},{lac:48},{lac:49},{lac:50}]
  public sal_th=[{th:5},{th:10},{th:15},{th:20},{th:25},{th:30},{th:35},{th:40},{th:45},{th:50},{th:55},{th:60},{th:65},{th:70},{th:75},{th:80},{th:85},{th:90},{th:95}]
  
  constructor(private http: HttpClient, private fb: FormBuilder,private router:Router) { }


  RegForm =this.fb.group({

    Name:['',Validators.required],
    Email:['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
    Country:['',Validators.required],
    City:['',Validators.required],
    Mobile:['',Validators.required],
    EducationDetails:['',Validators.required],
    WorkExperiance:['',Validators.required],
    Exp_Month:['',],
    AnnualSalary:['',Validators.required],
    Sal_In_Thousand:['',],
    KeySkills:['',Validators.required],
    gender: ['', [Validators.required]]
    
  })


c_list:any[];


  GetCountry(){

    this.http.get(this.rootURL + 'country/read-api.php').subscribe( (res:any)=>{
    
      this.country_list =res;
      console.log(this.country_list);

      console.log(this.month);
      
    })
   

    
    
    
  }
  
  SubmitResume( mypath:string ){

    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');

    var body=  {
      Name:this.RegForm.value.Name,
       Gender:this.RegForm.value.gender,
   //   Gender:'male',
      Email:this.RegForm.value.Email,
      CountryId:this.RegForm.value.Country,
      City:this.RegForm.value.City,
      Mobile:this.RegForm.value.Mobile,
      EducationDetails:this.RegForm.value.EducationDetails,
      WorkExperiance:this.RegForm.value.WorkExperiance,
      Exp_Month:this.RegForm.value.Exp_Month,
      AnnualSalary:this.RegForm.value.AnnualSalary,
      Sal_In_Thousand:this.RegForm.value.Sal_In_Thousand,
      KeySkills:this.RegForm.value.KeySkills,
      ImgPath:mypath
  }

  let data = JSON.stringify(body);

  return this.http.post<any>(this.rootURL + 'recruitments/create-api.php', data).pipe(catchError(this.handleError))
  }


  private handleError(errorReponce:HttpErrorResponse){
    if  (errorReponce.error instanceof ErrorEvent)
    {
      // - A Client-side or network error occureed.Handle it accordingly.
     console.error('An error occurred:',errorReponce.error.message);
      
    }
    else{
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code',errorReponce );
      
    }
    return throwError('SomeThing bad happend;Please try again later.');

    
  }
}
