import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import {  throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AboutUs, WhyUs, Services } from '../shared/myweb.modules'
import { Title } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class MyservicesService {

  readonly rootURL = environment.rootURL;
  public ImgURL=environment.imgPath;
  constructor(private http: HttpClient) { }

  // --- code start
  list: AboutUs[];

  list2: AboutUs[];
  WhyUslist: WhyUs[];
  servicelist: Services[];
 public resume_list : any[];
  formData: AboutUs = {
    aboutus_Id: null,
    about_Content: null,
    is_Active: null,
  };

  formData_WhyUs: WhyUs = {
    WhyUs_Id: null,
    Title: null,
    Content: null,
    ImgPath: null,
    IsActive: null
  }

  form_services: Services = {

    ServiceId: null,
    ServiceTitle: null,
    ClassName: null,
    IsActive: null
  }
  // -- fetch all About us Content
  GetAllAboutUsData() {
    this.http.get(this.rootURL + 'about/read-api.php').subscribe((result:any)=>{
      this.list =result;
    })
     
  }


  GetService_Home() {

   
    this.http.get(this.rootURL + 'services/get-three-data.php').subscribe((result:any)=>{
      this.list2 =result;
     
   

     
    })
     
  }

  /// -- Fetch Why Us Data
  GetAllWhyUsData() {
    this.http.get(this.rootURL + 'whyus/read-api.php')
      .toPromise()
      .then(res => this.WhyUslist = res as WhyUs[]);
  }


  /// -- Fetch Services Data
  GetAllServicesData() {
      this.http.get<any>(this.rootURL + 'services/read-api.php').pipe(map(data => {})).subscribe(result => {
        console.log(result);
      });
  
  
      // .toPromise()
      // .then(res => this.servicelist = res as Services[]);
  }


  GetResumeAllData() {
    this.http.get<any>(this.rootURL + 'recruitments/read-api.php').subscribe( (res:any)=>{
      this.resume_list=res;
      console.log(this.resume_list);
      
    })
    

    


    // .toPromise()
    // .then(res => this.servicelist = res as Services[]);
}
}
