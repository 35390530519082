import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeHeaderService {
  readonly rootURL = environment.rootURL;
  public home_header_list: any[];
  public home_header_active_list: any[];
  public service_imgPath:string='no data uploaded';
  constructor(private fb: FormBuilder, private http: HttpClient) { }


  form: FormGroup = this.fb.group({
    Home_HeaderId : [null],
    ContactNo: ['', Validators.required],
    EmailId: ['', Validators.required],
    TimeSlot: ['', Validators.required],
    Days_Name: ['', Validators.required],
  })


  GetRecords() {

    return this.http.get<any>(this.rootURL + 'home_header/read-api.php').subscribe((response: any) => {

      console.log(response);
      
      this.home_header_list = response;

     
      
    })
  }


  SaveRecors(imgpath:any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      // TestimonialID:this.form.controls.TestimonialID.value,
      ContactNo: this.form.controls.ContactNo.value,
      EmailId: this.form.controls.EmailId.value,
      TimeSlot: this.form.controls.TimeSlot.value,
      Days_Name: this.form.controls.Days_Name.value,
      Logo_ImgPath: imgpath,
      
    }
    let data = JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'home_header/create-api.php', data).pipe(catchError(this.handleError))
  }


  UpdateRecords(imgpath:any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      Home_HeaderId: this.form.controls.Home_HeaderId.value,
      ContactNo: this.form.controls.ContactNo.value,
      EmailId: this.form.controls.EmailId.value,
      TimeSlot: this.form.controls.TimeSlot.value,
      Days_Name: this.form.controls.Days_Name.value,
      Logo_ImgPath: imgpath,
     
    }
    let data = JSON.stringify(body);

    console.log(data);
    
    return this.http.put<any>(this.rootURL + 'home_header/update-api.php' ,data).pipe(catchError(this.handleError))
  }
  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }


  DeleteRecords(id:number) {

    var body ={
      Home_HeaderId:id
    }

    let data =JSON.stringify(body);
    return this.http.put<any>(this.rootURL + 'home_header/delete-api.php',data);
      
    
  }

}
