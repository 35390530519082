import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { Why_Us } from './myweb.modules';

@Injectable({
  providedIn: 'root'
})
export class WhyusService {
  private why_list:Observable<Why_Us[]>;
  readonly rootURL = environment.rootURL;
  constructor(private http: HttpClient, private fb: FormBuilder) { }


  form: FormGroup = this.fb.group({
    $key: [null],
    _title: ['', Validators.required],
    _content: ['', Validators.required],
    _isActive: ['', Validators.required],
  });

  initializeFormGroup() {
    this.form.setValue({
      $key: null,
      _title: '',
      _content: '',
      _isActive: true,
      
    });
  }
  GetWhyUsAllData() {

    return this.http.get(this.rootURL + 'whyus/read-api.php').subscribe((result:any )=>{

      this.why_list=result;
    });
  }

  
  InsertWhy_US(){
     

      let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
     
      title: this.form.value._title,
      content: this.form.value._content,
      isActive: this.form.value._isActive,
    }
    let data = JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'whyus/create-api.php', data).pipe(catchError(this.handleError))
  }

  UpdateWhy_US(){


  
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      whyUs_Id: this.form.value.$key,
      title: this.form.value._title,
      content: this.form.value._content,
      isActive: this.form.value._isActive,
    }
    let data = JSON.stringify(body);
    this.GetWhyUsAllData();
    return this.http.put<any>(this.rootURL + 'whyus/update-api.php', data).pipe(catchError(this.handleError))
  }

  
  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }



   // -- -IMG Section 


   SaveImgPath(imgpath: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      ImgPath: imgpath,
    }
    let data = JSON.stringify(body);

    return this.http.post<any>(this.rootURL + 'whyus_images/create-api.php', data).pipe(catchError(this.handleError))

  }


  public ImgPath:string
  GetWhyUsImages() {

    return this.http.get<any>(this.rootURL + 'whyus_images/read-api.php')


    

      
  }



  UpdateWhyUsImage(imgpath: string, WhyUs_ImageId: number) {

    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      WhyUs_ImageId: WhyUs_ImageId,
      ImgPath: imgpath,
     
    }
    let data = JSON.stringify(body);

    console.log(data
    );

    return this.http.put<any>(this.rootURL + 'whyus_images/update-api.php' , data).pipe(catchError(this.handleError))
  }
  DeleteRecords(id:number) {

    var body ={
      WhyUs_Id:id
    }

    let data =JSON.stringify(body);
    return this.http.put<any>(this.rootURL + 'whyus/delete-api.php',data);
      
    
  }
}
