import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../shared/blog.service';
@Component({
  selector: 'app-artical',
  templateUrl: './artical.component.html',
  styleUrls: ['./artical.component.css']
})
export class ArticalComponent implements OnInit {
  jwt: string;
  constructor(private route: ActivatedRoute,public service : BlogService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
       // console.log('Got the JWT as: ', params['blog']);
        this.jwt =  params['blog'];
      }
    )

   
    this.GetRecordsById(this.jwt);
  }




  GetRecordsById(id){

    
    this.service.GetById(id);
  }

}
