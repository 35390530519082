import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { IndustryServicesComponent } from './industry-services/industry-services.component';
import { PostResumeComponent } from './post-resume/post-resume.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { WhyusComponent } from './whyus/whyus.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AboutusComponent } from './admin/aboutus/aboutus.component';
import { MaterialsComponentModule } from './materials-component/materials-component.module';
import { AdminHeaderComponent } from './admin/admin-header/admin-header.component';
import { AdminFooterComponent } from './admin/admin-footer/admin-footer.component';
import { AdminSidenavComponent } from './admin/admin-sidenav/admin-sidenav.component';
import { LoginComponent } from './admin/login/login.component';
import { AddAboutusComponent } from './admin/add-aboutus/add-aboutus.component';
import { AboutusserviceComponent } from './shared/aboutusservice/aboutusservice.component';
import{ToastrModule} from 'ngx-toastr';
import { ListAboutusComponent } from './admin/list-aboutus/list-aboutus.component';
import { ListWhyusComponent } from './admin/whyus/list-whyus/list-whyus.component';
import { AddWhyusComponent } from './admin/whyus/add-whyus/add-whyus.component';
import { AdminWhyusComponent } from './admin/whyus/admin-whyus/admin-whyus.component';
import { ResumeComponent } from './admin/resume/resume.component';
import { TestimonialComponent } from './admin/testimonial/testimonial.component';
import { TestimonialListComponent } from './admin/testimonial-list/testimonial-list.component';
import { TestimonialAddComponent } from './admin/testimonial-add/testimonial-add.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { BlogComponent } from './admin/blog/blog.component';
import { BlogAddComponent } from './admin/blog-add/blog-add.component';
import { BlogListComponent } from './admin/blog-list/blog-list.component';
import { HomeHeaderComponent } from './admin/home-header/home-header.component';
import { HomeHeaderAddComponent } from './admin/home-header-add/home-header-add.component';
import { HomeHeaderListComponent } from './admin/home-header-list/home-header-list.component';
import { IndustryComponent } from './admin/industry/industry.component';
import { IndustryAddComponent } from './admin/industry-add/industry-add.component';
import { IndustryListComponent } from './admin/industry-list/industry-list.component';
import { AdminServiceComponent } from './admin/admin-service/admin-service.component';
import { AdminServiceAddComponent } from './admin/admin-service-add/admin-service-add.component';
import { AdminServiceListComponent } from './admin/admin-service-list/admin-service-list.component';
import { AdminServiceDetailListComponent } from './admin/admin-service-detail-list/admin-service-detail-list.component';
import { AdminServiceDetailAddComponent } from './admin/admin-service-detail-add/admin-service-detail-add.component';
import { AdminServiceDetailComponent } from './admin/admin-service-detail/admin-service-detail.component';
import { HomeBlogComponent } from './home-blog/home-blog.component';
import { HomeIndustryComponent } from './home-industry/home-industry.component';
import { HomeServiceComponent } from './home-service/home-service.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { AboutusDetailsComponent } from './aboutus-details/aboutus-details.component';
import { OurservicesDetailsComponent } from './ourservices-details/ourservices-details.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { ArticalComponent } from './artical/artical.component';
import { ChildComponent } from './child/child.component';

import { AddContactusComponent } from './admin/add-contactus/add-contactus.component';
import { ListContactusComponent } from './admin/list-contactus/list-contactus.component';
import { AdminContactusComponent } from './admin/admin-contactus/admin-contactus.component';

import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';

import {Limit } from '../../src/app/shared/limit';
import { ViewResumeDetailsComponent } from './admin/view-resume-details/view-resume-details.component';
import { HomeSliderComponent } from './admin/home-slider/home-slider.component';
import { HomeAddSliderComponent } from './admin/home-add-slider/home-add-slider.component';
import { HomeListSliderComponent } from './admin/home-list-slider/home-list-slider.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InquiryComponent } from './admin/inquiry/inquiry.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    IndustryServicesComponent,
    PostResumeComponent,
    ContactUsComponent,
    AboutUsComponent,
    WhyusComponent,
    DashboardComponent,
    AboutusComponent,
    AdminHeaderComponent,
    AdminFooterComponent,
    AdminSidenavComponent,
    LoginComponent,
    AddAboutusComponent,
    AboutusserviceComponent,
    ListAboutusComponent,
    ListWhyusComponent,
    AddWhyusComponent,
    AdminWhyusComponent,
    ResumeComponent,
    TestimonialComponent,
    TestimonialListComponent,
    TestimonialAddComponent,
    TestimonialsComponent,
    BlogComponent,
    BlogAddComponent,
    BlogListComponent,
    HomeHeaderComponent,
    HomeHeaderAddComponent,
    HomeHeaderListComponent,
    IndustryComponent,
    IndustryAddComponent,
    IndustryListComponent,
    AdminServiceComponent,
    AdminServiceAddComponent,
    AdminServiceListComponent,
    AdminServiceDetailListComponent,
    AdminServiceDetailAddComponent,
    AdminServiceDetailComponent,
    HomeBlogComponent,
    HomeIndustryComponent,
    HomeServiceComponent,
    NotfoundComponent,
    AboutusDetailsComponent,
    OurservicesDetailsComponent,
    BlogDetailsComponent,
    ArticalComponent,
    ChildComponent,
    
    AddContactusComponent,
    ListContactusComponent,
    AdminContactusComponent,
    Limit,
    ViewResumeDetailsComponent,
    HomeSliderComponent,
    HomeAddSliderComponent,
    HomeListSliderComponent,
    InquiryComponent,
 
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialsComponentModule,
    ToastrModule,
   ToastrModule.forRoot(
     {
       timeOut:3000,
       progressBar:true,
       progressAnimation:"decreasing",
       preventDuplicates:true
     }
   ),
   A11yModule,
   
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    AngularEditorModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents:
  [
    AddAboutusComponent,
    AddWhyusComponent,
    TestimonialAddComponent,
    BlogAddComponent,
    HomeHeaderAddComponent,
    IndustryAddComponent,
    AdminServiceAddComponent,
    AdminServiceDetailAddComponent,
    AddContactusComponent,
    ViewResumeDetailsComponent,
    HomeAddSliderComponent
  ]
})
export class AppModule { }
