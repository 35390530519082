import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HomesliderService } from 'src/app/shared/homeslider.service';
import { HomeAddSliderComponent } from '../home-add-slider/home-add-slider.component';


@Component({
  selector: 'app-home-list-slider',
  templateUrl: './home-list-slider.component.html',
  styleUrls: ['./home-list-slider.component.css']
})
export class HomeListSliderComponent implements OnInit {

  constructor(private service : HomesliderService,private dialog: MatDialog,private toastr: ToastrService) { }

  ngOnInit() {
    this.service.GetRecords();
  }

  editrecords(_head: any) {
    this.ShowPopUp();



    this.service.service_imgPath=_head.img_path;
    this.service.form.patchValue({

      home_slider_id:_head.home_slider_id ,
      img_path:_head.img_path ,
      content: _head.content,
      title: _head.title,
      is_active:_head.is_active ,

    })
  }


  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "700px";
    this.dialog.open(HomeAddSliderComponent, diallogConfig)

  }

  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.service.DeleteRecords(id).subscribe(res => {
        this.service.GetRecords();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  }

}
