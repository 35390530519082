import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  readonly rootURL = environment.rootURL;
  constructor(private http: HttpClient, private fb: FormBuilder) { }
public uname ='admin';
public pwd='admin';

  form: FormGroup = this.fb.group({
   
    UserName: ['', Validators.required],
    Password: ['', Validators.required],
    
  });

  initializeFormGroup() {
    this.form.setValue({
    
      UserName: '',
      Password: '',
     
      
    });
  }


  Login(){

    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');

    var body = {
     
      UserName: this.form.value.UserName,
      Password: this.form.value.Password,

     
      
    }

    let data =JSON.stringify(body);

   
    return this.http.post<any>(this.rootURL + 'services/login-api.php', data).pipe(catchError(this.handleError))

  }

  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }
}
 