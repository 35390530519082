import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminServiceDetailService {
  readonly rootURL = environment.rootURL;
  public service_list :any[];
  public service_imgPath:string='no data uploaded';
  constructor(private fb: FormBuilder, private http: HttpClient) { }


  form: FormGroup = this.fb.group({
    ServiceDetailId : [null],
    ServiceD_Title: ['', Validators.required],
    ServiceD_Content: ['', Validators.required],
    FK_ServiceId: ['', Validators.required],
   
  })


  Get_service_Details_master() {

   
    return this.http.get<any>(this.rootURL + 'services/get-all-data.php').subscribe((response: any) => {
      this.service_list = response;

      console.log(this.service_list); 
      
    })
  }


  SaveRecors() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      // TestimonialID:this.form.controls.TestimonialID.value,
     // ServiceDetailId: this.form.controls.ServiceDetailId.value,
      ServiceD_Title: this.form.controls.ServiceD_Title.value,
      ServiceD_Content: this.form.controls.ServiceD_Content.value,
      FK_ServiceId: +this.form.controls.FK_ServiceId.value,
     
      
    }
    let data = JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'servicedetails/create-api.php', data).pipe(catchError(this.handleError))
  }

  UpdateRecords() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
     
     ServiceDetailId: this.form.controls.ServiceDetailId.value,
     ServiceD_Title: this.form.controls.ServiceD_Title.value,
     ServiceD_Content: this.form.controls.ServiceD_Content.value,
     FK_ServiceId: +this.form.controls.FK_ServiceId.value,
     
     
    }
    let data = JSON.stringify(body);

    console.log(data);
    
    return this.http.put<any>(this.rootURL + 'servicedetails/update-api.php', data).pipe(catchError(this.handleError))
  }


  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }

  DeleteRecords(id:number) {

    var body ={
      ServiceDetailId:id
    }

    let data=JSON.stringify(body);

    return this.http.put<any>(this.rootURL + 'servicedetails/delete-api.php',data);
      
    
  }



  
  get_service_details_by_id(id :number) {
    var body ={
      FK_ServiceId:id
    }
    let data=JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'servicedetails/get-service-details-by-id.php',data).subscribe((response: any) => {
      this.service_list = response;
      console.log(this.service_list); 
      
    })
  }
}
