import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PostResumeComponent } from 'src/app/post-resume/post-resume.component';
import { MyservicesService } from 'src/app/shared/myservices.service';
import { ResumeService } from 'src/app/shared/resume.service';
import { ViewResumeDetailsComponent } from '../view-resume-details/view-resume-details.component';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit { 

  constructor( private service : MyservicesService ,private dialog: MatDialog,public _resumeservice : ResumeService) { }

  ngOnInit() {

    this.service.GetResumeAllData();
  }

  ShowPopUp(x :any) {

    this._resumeservice.RegForm.patchValue({
      Name: x.name,
      Email: x.email,
      Country: x.countryId,
      City: x.city,
      Mobile: x.mobile,
      EducationDetails:x.educationDetails,
      WorkExperiance:x.workExperiance,

      Exp_Month:x.exp_Month,
      AnnualSalary:x.annualSalary,
      Sal_In_Thousand:x.sal_In_Thousand,
      KeySkills:x.keySkills,
      gender:x.gender,
    })
    
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "700px";
    this.dialog.open(ViewResumeDetailsComponent, diallogConfig)

  }

}
