import { Component, OnInit } from '@angular/core';
import { AboutusserviceService } from 'src/app/shared/aboutusservice.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-aboutus',
  templateUrl: './add-aboutus.component.html',
  styleUrls: ['./add-aboutus.component.css']
})
export class AddAboutusComponent implements OnInit {
  button = 'Submit';
  isLoading = false;
  constructor(private service: AboutusserviceService,
    public dialogRef: MatDialogRef<AddAboutusComponent>,
    private toastr: ToastrService,private router:Router
    ) { }
  departments = [
    { id: 3, value: 'Dep 1' },
    { id: 2, value: 'Dep 2' },
    { id: 3, value: 'Dep 3' }];





  ngOnInit() {
   
  }

  onClear() {
    this.service.form.reset();
   // this.service.initializeFormGroup();
  }


 

  OnSubmit() {

    this.isLoading = true;
    this.button = 'Processing';
    if (this.service.form.controls.$key.value == null) {
      /// -- Insert
      this.service.SaveRecords().subscribe(res => {
        this.toastr.success('success!', 'Your Record Has been Saved.!');
        this.isLoading = false;
        this.button = 'Submit';
        this.service.GetAboutUsAllData();
        console.log(res);
        this.onClear();
        this.onNoClick();
      this.router.navigateByUrl('/about-us');
      },
        err => {
          console.log(err);
        }
      )
    }
    else {
      /// -- Update

      this.service.UpdateRecords().subscribe((result:any)=>{

        this.toastr.warning('updated!', 'Your Record Has been Updated.!');
        this.isLoading = false;
        this.button = 'Submit';
        this.service.GetAboutUsAllData();
        this.onClear();
        this.onNoClick();
        
      }
      )
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
