import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AdminServiceDetailService } from 'src/app/shared/admin-service-detail.service';
import { AdminServiceDetailAddComponent } from '../admin-service-detail-add/admin-service-detail-add.component';

@Component({
  selector: 'app-admin-service-detail-list',
  templateUrl: './admin-service-detail-list.component.html',
  styleUrls: ['./admin-service-detail-list.component.css']
})
export class AdminServiceDetailListComponent implements OnInit {
 
  selectedval:string;
  constructor(private service : AdminServiceDetailService,private dialog: MatDialog,private toastr: ToastrService) { }

  ngOnInit() {

    this.service.Get_service_Details_master();
  }
 

  editrecords(_serv: any) {

    console.log(_serv);
    
    this.ShowPopUp();
   // let num = _serv.servicesId;//number

   
  //  let stringForm = num.toString();

    
    this.service.form.patchValue({



      
      ServiceDetailId:_serv.serviceDetailId ,
      ServiceD_Title:_serv.serviceD_Title ,
      ServiceD_Content:_serv.serviceD_Content ,
      FK_ServiceId:_serv.serviceId ,
    
    })
  }


  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "700px";
    this.dialog.open(AdminServiceDetailAddComponent, diallogConfig)

  }


  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.service.DeleteRecords(id).subscribe(res => {
        this.service.Get_service_Details_master();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  } 

 
}
