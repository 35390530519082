import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IndustryService } from '../shared/industry.service';

@Component({
  selector: 'app-industry-services',
  templateUrl: './industry-services.component.html',
  styleUrls: ['./industry-services.component.css']
})
export class IndustryServicesComponent implements OnInit {
type:any;
  constructor( private service : IndustryService ,private activatedRoute: ActivatedRoute) {

    this.activatedRoute.params.subscribe(params => {
      this.type = params['type'];
      if (this.type==='it') {
        this.service.GetDataByTypeId(1);
      }
     else if (this.type==='non-it') {
      this.service.GetDataByTypeId(2);
     }
  else{
    this.service.GetRecords();
  }
    })
  }

  ngOnInit() {


  }

}
