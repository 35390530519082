import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TestimonialService } from 'src/app/shared/testimonial.service';
import { TestimonialAddComponent } from '../testimonial-add/testimonial-add.component';
@Component({
  selector: 'app-testimonial-list',
  templateUrl: './testimonial-list.component.html',
  styleUrls: ['./testimonial-list.component.css']
})
export class TestimonialListComponent implements OnInit {

  constructor(private testimonial_service:TestimonialService,private dialog: MatDialog,private toastr: ToastrService) { }

  ngOnInit() {

    this.testimonial_service.GetRecords();
  }

  
  editrecords(testimonial: any) {
    this.ShowPopUp();

    this.testimonial_service.service_imgPath=testimonial.imgPath;
    this.testimonial_service.form.patchValue({

      TestimonialID:testimonial.testimonialID ,
    TestimonialContent:testimonial.testimonialContent ,
    FirstName: testimonial.firstName,
    LastName:testimonial.lastName ,
    Text_One:testimonial.text_One ,
    Text_Two:testimonial.text_Two ,
   // ImgPath: [''],
    IsActive:testimonial.isActive ,
    })
  }

  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "700px";
    this.dialog.open(TestimonialAddComponent, diallogConfig)

  }


  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.testimonial_service.DeleteRecords(id).subscribe(res => {
        this.testimonial_service.GetRecords();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  }
}
