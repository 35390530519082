import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country } from '../shared/myweb.modules';
import { ResumeService } from '../shared/resume.service';

@Component({
  selector: 'app-post-resume',
  templateUrl: './post-resume.component.html',
  styleUrls: ['./post-resume.component.css']
})
export class PostResumeComponent implements OnInit {

  constructor(private resume_service: ResumeService, private http: HttpClient, private toastr: ToastrService,private router :Router) { }
  public progress: number;
  public message: string;
  readonly rootURL = environment.rootURL;
  readonly imgURL = environment.imgPath;
  public fileToUpload: any;
  private country_list:Observable<Country>;
     url :any;

  @Output() public onUploadFinished = new EventEmitter();
  ngOnInit() {
    this.resume_service.RegForm.reset();
    this.getcountry();

   
  }

 public countrycode:any=0;
  getcountry(){

    this.resume_service.GetCountry()
    
    
  }

  getcode(event:Event){

   
console.log((event.target as HTMLSelectElement).value);
this.countrycode=   (event.target as HTMLSelectElement).value;
  }

  imgpath: string;
// -- Upload File

// uploadFile1(event) {
//   if (event.target.files && event.target.files[0]) {
//     var reader = new FileReader();

//     reader.readAsDataURL(event.target.files[0]); // read file as data url

//     reader.onload = (event) => { // called once readAsDataURL is completed
//    //   this.url = event.target.result;

//    console.log(event.target.result);
   
//     }
//   }
// }



//fileToUpload: any;
imageUrl: any;


handleFileInput(file: FileList) {
  this.fileToUpload = file.item(0);

  //Show image preview
  let reader = new FileReader();
  reader.onload = (event: any) => {
    this.imageUrl = event.target.result;
  }
  reader.readAsDataURL(this.fileToUpload);
}
public uploadFile = (files) => {


  if (files.length === 0) {
    return;
  }

   this.fileToUpload = <File>files[0];
   var reader = new FileReader();
   reader.readAsDataURL(files[0]); // read file as data url
  console.log(reader.result);

  reader.onload = (event) => { // called once readAsDataURL is completed
       //   this.url = event.target.result;
    
      // console.log(event.target.result);
       
        }
  
}

OnSubmit(){
  const formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
  this.http.post(this.rootURL + 'uploads/file-upload.php', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.onUploadFinished.emit(event.body);
         
          this.SaveRecords(event.body);
        }
      });
}

  SaveRecords(imgpath) {

    this.resume_service.SubmitResume(imgpath).subscribe(res => {
      if (res > 0) {

        this.toastr.success('Resume Upload Successfull..', 'title');
        this.resume_service.RegForm.reset();
       this.router.navigateByUrl('/home');
      }
    })


  }


}
