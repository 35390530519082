import { Component, OnInit } from '@angular/core';
import { MyservicesService } from 'src/app/shared/myservices.service';
import * as $ from 'jquery' ;
@Component({
  selector: 'app-admin-sidenav',
  templateUrl: './admin-sidenav.component.html',
  styleUrls: ['./admin-sidenav.component.css']
})
export class AdminSidenavComponent implements OnInit {

  constructor(private service :MyservicesService) { }

  ngOnInit() {
    
    
  }
   
}
