import { Component, OnInit } from '@angular/core';
import { IndustryService } from '../shared/industry.service';

@Component({
  selector: 'app-home-industry',
  templateUrl: './home-industry.component.html',
  styleUrls: ['./home-industry.component.css']
})
export class HomeIndustryComponent implements OnInit {

  constructor( private service : IndustryService) { }

  ngOnInit() {

    this.service.GetRecords_forHome();
  }

}
