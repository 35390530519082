import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Why_Us_Images } from 'src/app/shared/myweb.modules';
import { WhyusService } from 'src/app/shared/whyus.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-whyus',
  templateUrl: './add-whyus.component.html',
  styleUrls: ['./add-whyus.component.css']
})
export class AddWhyusComponent implements OnInit {
  public progress: number;
  public message: string;
  readonly rootURL = environment.rootURL;
  readonly imgURL = environment.imgPath;
  public fileToUpload: any;
  @Output() public onUploadFinished = new EventEmitter();
  img_list: Observable<Why_Us_Images[]>;
  button = 'Submit';
  isLoading = false;
  constructor( private whyus_service :WhyusService, public dialogRef: MatDialogRef<AddWhyusComponent>, private toastr: ToastrService,private http: HttpClient) { }

  ngOnInit() {

   // this.whyus_service.form.reset();
   //this.GetImg();
  }
  onSubmit() {

    
    if (this.whyus_service.form.valid) {
      this.isLoading = true;
      this.button = 'Processing';
     
      if (!this.whyus_service.form.get('$key').value)
      this.whyus_service.InsertWhy_US().subscribe(res=>{

        if  (res >0){
          this.toastr.success('success!', 'Your Record Has been Saved.!');
          this.isLoading = false;
          this.button = 'Submit';
          this.onClose();
          this.whyus_service.GetWhyUsAllData();
        }

      });
        // this.whyus_service.insertEmployee(this.service.form.value);
      else
      this.whyus_service.UpdateWhy_US().subscribe(res=>{
console.log(res);

        if  (res >0){
          this.toastr.warning('success!', 'Your Record Has been Updated.!');
          this.isLoading = false;
          this.button = 'Submit';
        
          this.whyus_service.GetWhyUsAllData();
          this.onClose();
        }

      });
     
    }
  }


  onClose() {

    this.whyus_service.form.reset();
    this.whyus_service.initializeFormGroup();
    this.dialogRef.close();
 
  }

 
 
}
