import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { MatDialog, MatDialogConfig } from '@angular/material'
import { AddAboutusComponent } from '../add-aboutus/add-aboutus.component';
import { ToastrService } from 'ngx-toastr';
import { subscribeOn } from 'rxjs/operators';
import { AboutusserviceService } from 'src/app/shared/aboutusservice.service';
import { parse } from 'querystring';
import { AboutUs, AboutUsImages } from 'src/app/shared/myweb.modules';
import { Observable } from 'rxjs';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  @Output() public onUploadFinished = new EventEmitter();
  public progress: number;
  public message: string;
  readonly rootURL = environment.rootURL;
  readonly imgURL = environment.imgPath;
  public fileToUpload: any;
  constructor(private service: AboutusserviceService, private dialog: MatDialog, private toastr: ToastrService, private http: HttpClient) { }
  listData: MatTableDataSource<any>;
  about_list: Observable<AboutUs[]>;
  img_list: Observable<AboutUsImages[]>;
  displayedColumns: string[] = ['#', 'content', 'status', 'actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  searchKey: string;
  ngOnInit() {
    this.GetImg();
    // this.service.GetAboutUsActiveData().subscribe
    //   (

    //     (res: any) => {

    //       console.log(res);
    //       this.about_list = res;
    //       //  this.about_list=JSON.parse(res);
    //       this.listData = new MatTableDataSource(res);

    //       this.listData.sort = this.sort;
    //       this.listData.paginator = this.paginator;
    //       this.listData.filterPredicate = (data, filter) => {
    //         return this.displayedColumns.some(ele => {
    //           return ele != 'actions' && data[ele].toLowerCase().indexOf(filter) != -1;
    //         });
    //       };

    //     },
    //     err => {
    //       error => console.log(err);

    //     }

    //   )





  };




  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "1000px";
    this.dialog.open(AddAboutusComponent, diallogConfig)

  }






  uploadFile(files) {
    this.fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    this.http.post(this.rootURL + 'uploads/file-upload.php', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {


        
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.onUploadFinished.emit(event.body);
        
          
        
         
          this.SaveImgPath(event.body);
        }
      });
  }

  imgpath: string;
  aboutUsImageId:number=null;

  SaveImgPath(imgpath) {
    var mypath = imgpath;


   
    if (this.aboutUsImageId == null) {
      this.Insert(mypath);
    }
    else {
      this.Update(mypath, this.aboutUsImageId);
    }
  }


  Insert(mypath) {
    this.service.SaveImgPath(mypath).subscribe(
      (res: any) => {
        this.toastr.success('Image Upload Successfull..', 'title');
        this.GetImg();

        
      },
      err => {
        error => console.log(err);
      });
  }

  Update(mypath, AboutUsImageId) {
    this.service.UpdateAboutUsImage(mypath, AboutUsImageId).subscribe(
      (res: any) => {
        this.toastr.success('Image Upload Successfull..', 'title');
        this.GetImg();
      },
      err => {
        error => console.log(err);
      });
  }

  GetImg() {

    this.service.GetAboutUsImage().subscribe((res: any) => {


      this.img_list = res;

      console.log(this.img_list);
      this.imgpath =  this.img_list[0].imgPath;
      this.aboutUsImageId =this.img_list[0].aboutUsImageId;
    })
  }

}
