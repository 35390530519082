import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomesliderService {
  readonly rootURL = environment.rootURL;
  public slider_list: any[];
  public service_imgPath:string='no data uploaded';
  constructor(private fb: FormBuilder, private http: HttpClient) { }



  form: FormGroup = this.fb.group({
    home_slider_id : [null],
    img_path: ['', Validators.required],
    title: ['', Validators.required],
    content: ['', Validators.required],
    is_active: ['', Validators.required],

  })



  GetRecords() {
    return this.http.get<any>(this.rootURL + 'home_slider/read-api.php').subscribe((response: any) => {
      console.log(response);
      this.slider_list = response;
    })
  }

  SaveRecors(imgpath:any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      content: this.form.controls.content.value,
      title: this.form.controls.title.value,
      is_active: this.form.controls.is_active.value,
      img_path: imgpath,

    }
    let data = JSON.stringify(body);

    console.log(data);

    return this.http.post<any>(this.rootURL + 'home_slider/create-api.php', data).pipe(catchError(this.handleError))
  }
  UpdateRecords(imgpath:any) {


    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      home_slider_id: this.form.controls.home_slider_id.value,
      content: this.form.controls.content.value,
      title: this.form.controls.title.value,
      is_active: this.form.controls.is_active.value,
      img_path: imgpath,

    }
    let data = JSON.stringify(body);

    console.log(data);

    return this.http.put<any>(this.rootURL + 'home_slider/update-api.php' ,data).pipe(catchError(this.handleError))
  }


  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }



  DeleteRecords(id:number) {

    var body ={
      home_slider_id:id
    }

    let data =JSON.stringify(body);
    return this.http.put<any>(this.rootURL + 'home_slider/delete-api.php',data);


  }

}


