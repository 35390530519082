import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { IndustryService } from 'src/app/shared/industry.service';
import { IndustryAddComponent } from '../industry-add/industry-add.component';

@Component({
  selector: 'app-industry-list',
  templateUrl: './industry-list.component.html',
  styleUrls: ['./industry-list.component.css']
})
export class IndustryListComponent implements OnInit {

  constructor(private service : IndustryService,private dialog: MatDialog,private toastr: ToastrService) { }

  ngOnInit() {
    this.service.GetRecords();
  }


  editrecords(_ind: any) {
    this.ShowPopUp();

    this.service.service_imgPath=_ind.imgPath;
    this.service.form.patchValue({

      IndustryId:_ind.industryId ,
      Ind_Title:_ind.ind_Title ,
    typeid:_ind.typeid
    })
  }


  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "700px";
    this.dialog.open(IndustryAddComponent, diallogConfig)

  }


  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.service.DeleteRecords(id).subscribe(res => {
        this.service.GetRecords();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  }

}
