import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  readonly rootURL = environment.rootURL;
  public Ind_list: any[];
  public Ind_list2: any[]
  public industry: any[];;
  public Blog_active_list: any[];
  public service_imgPath:string='no data uploaded';
  constructor(private fb: FormBuilder, private http: HttpClient) { }

  form: FormGroup = this.fb.group({
    IndustryId : [null],
    Ind_Title: ['', Validators.required],
    typeid: ['', Validators.required],

  })


  GetRecords() {



    return this.http.get<any>(this.rootURL + 'industry/read-api.php').subscribe((response: any) => {
      this.Ind_list = response;
      this.industry = response;
      console.log(this.Ind_list);

    })
  }


  GetRecords_forHome() {
    return this.http.get<any>(this.rootURL + 'industry/get-three-data.php').subscribe((response: any) => {
      this.Ind_list2 = response;

      console.log(this.Ind_list2);

    })
  }

  SaveRecors(imgpath:any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      typeid:this.form.controls.typeid.value,
      Ind_Title: this.form.controls.Ind_Title.value,
      ImgPath: imgpath,

    }
    let data = JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'industry/create-api.php', data).pipe(catchError(this.handleError))
  }


  GetDataByTypeId(typeId:number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      typeid:typeId,
    }
    let data = JSON.stringify(body);
    return this.http.post<any>(this.rootURL + 'industry/read-api-by-id.php', data).subscribe((response: any) => {
      this.industry = response;
      console.log(this.industry);

    })
  }

  UpdateRecords(imgpath:any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    var body = {
      typeid: + this.form.controls.typeid.value,
      IndustryId: this.form.controls.IndustryId.value,
      Ind_Title: this.form.controls.Ind_Title.value,
      ImgPath: imgpath,

    }
    let data = JSON.stringify(body);

    console.log(data);

    return this.http.put<any>(this.rootURL + 'industry/update-api.php' , data).pipe(catchError(this.handleError))
  }


  private handleError(errorReponce: HttpErrorResponse) {
    if (errorReponce.error instanceof ErrorEvent) {
      // - A Client-side or network error occureed.Handle it accordingly.
      console.error('An error occurred:', errorReponce.error.message);

    }
    else {
      // console.error(`Backend returend Code ${error.status},`+`body was:${error.error.message}`);
      console.error('Backend returend Code', errorReponce);

    }
    return throwError('SomeThing bad happend;Please try again later.');


  }

  DeleteRecords(id:number) {

    var body ={
      IndustryId:id
    }

    let data =JSON.stringify(body);

    return this.http.put<any>(this.rootURL + 'industry/delete-api.php',data);


  }
}
