import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AboutusserviceService } from '../shared/aboutusservice.service';
import { MyservicesService } from '../shared/myservices.service';
import { AboutUsImages } from '../shared/myweb.modules';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  readonly imgURL = environment.imgPath;
  imgpath: string;
  img_list: Observable<AboutUsImages[]>;
  constructor( private service : MyservicesService,private about_service : AboutusserviceService) { }

  ngOnInit() {
// -- Get All Data On Refresh
    this.service.GetAllAboutUsData()
    this.GetImages();
  }


  GetImages(){

    this.about_service.GetAboutUsImage().subscribe((result:any)=>{
      this.img_list = result;
      this.imgpath =  this.img_list[0].imgPath;
    })
  }


}
