import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlogService } from '../shared/blog.service';
import { ContactusService } from '../shared/contactus.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor( public service : ContactusService,public fb: FormBuilder ) { }

  ngOnInit() {

    this.service.GetRecords();
   
  }

  get f() { return this.form.controls; }

  form: FormGroup = this.fb.group({
   
    description: [''],
    email: ['', Validators.required],
    fname: ['', Validators.required],
    lname: ['', Validators.required],
    mobile: ['',Validators.required],
    

  });



  initializePFormGroup() {
    this.form.setValue({
     
      description: '',
      email: '',
      fname: '',
      lname: '',
      mobile: '',
    });
  }

  onSubmit(){


    if (this.form.invalid) {
      return;
    }

    var body = {
      // TestimonialID:this.form.controls.TestimonialID.value,
      description: this.form.controls.description.value,
      email: this.form.controls.email.value,
      fname: this.form.controls.fname.value,
      lname: this.form.controls.lname.value,
      mobile: this.form.controls.mobile.value,
      
    }

    this.service.SaveInquiry(body).subscribe((res)=>{

     if (res >0){

      alert('form submited...!!!!!')

      this.form.reset();
     }
      
    })
    
  }
}
