import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlogService } from 'src/app/shared/blog.service';
import { environment } from 'src/environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-blog-add',
  templateUrl: './blog-add.component.html',
  styleUrls: ['./blog-add.component.css']
})
export class BlogAddComponent implements OnInit {


  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  public fileToUpload: any;
  readonly rootURL = environment.rootURL;
  public readonly _imgPath = environment.imgPath;
  public progress: number;
  public message: string;
  button = 'Submit';
  	isLoading = false;
  @Output() public onUploadFinished = new EventEmitter();
  constructor(private service: BlogService, private http: HttpClient, private toastr: ToastrService, private router: Router, public dialogRef: MatDialogRef<BlogAddComponent>) { }

  ngOnInit() {
  }
  imgpath: string;


  public uploadFile = (files) => {


    if (files.length === 0) {
      return;
    }

    this.fileToUpload = <File>files[0];
    console.log(this.fileToUpload);

  }

  onSubmit() {
    this.isLoading = true;
    this.button = 'Processing';
    let idInput = (<HTMLInputElement>document.getElementById('file')).files;
    if (idInput.length === 0) {
      return this.SaveData(this.service.service_imgPath);
    }
    else {
      const formData = new FormData();
      formData.append('file', this.fileToUpload, this.fileToUpload.name);
      this.http.post(this.rootURL + 'uploads/file-upload.php', formData, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response) {
            this.onUploadFinished.emit(event.body);
           
            this.SaveData(event.body);
          }
        });

    }


  }


  SaveData(imgpath) {

   
    if (this.service.form.controls.BlogPostID.value == null) {
      this.service.SaveRecors(imgpath).subscribe((result) => {
        if (result > 0) {
          this.toastr.success('Record Save Successfully..', 'success..!');
          this.isLoading = false;
        this.button = 'Submit';

          this.onClear();
        }
      })

    } else {
      this.service.UpdateRecords(imgpath).subscribe((result) => {
        if (result > 0) {
          this.toastr.success('Record Updated Successfully..', 'updated..!');
          this.isLoading = false;
        this.button = 'Submit';

          this.onClear();
        }
      })
    }

  }


  onClear() {
    this.service.form.reset();
    this.dialogRef.close();
    this.service.GetRecords();
    this.service.service_imgPath = 'no data';
  }

}
