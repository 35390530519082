import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TestimonialService } from '../shared/testimonial.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  readonly imgPath=environment.imgPath;
  constructor( private tservice : TestimonialService) { }

  ngOnInit() {

    this.tservice.Get_ActiveRecords();
  }

}
