import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Why_Us_Images } from 'src/app/shared/myweb.modules';
import { WhyusService } from 'src/app/shared/whyus.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-whyus',
  templateUrl: './admin-whyus.component.html',
  styleUrls: ['./admin-whyus.component.css']
})
export class AdminWhyusComponent implements OnInit {
  public progress: number;
  public message: string;
  readonly rootURL = environment.rootURL;
  readonly imgURL = environment.imgPath;
  public fileToUpload: any;
  @Output() public onUploadFinished = new EventEmitter();
  img_list: Observable<Why_Us_Images[]>;
  constructor(private whyus_service :WhyusService,private toastr: ToastrService,private http: HttpClient) { }

  ngOnInit() {

    this.GetImg();
  }



  imgpath: string;
  Whyus_imageId:number=null;
  uploadFile(files) {
    this.fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    this.http.post(this.rootURL + 'uploads/file-upload.php', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.onUploadFinished.emit(event.body);
        
         
          this.SaveImgPath(event.body);
        }
      });  
  }

  SaveImgPath(imgpath) {

    console.log(imgpath);
    
    var mypath = imgpath;


   
    if (this.Whyus_imageId == null) {
      this.Insert(mypath);
    }
    else {
      this.Update(mypath, this.Whyus_imageId);
    }
  }

  Insert(mypath) {
    this.whyus_service.SaveImgPath(mypath).subscribe(
      (res: any) => {
        this.toastr.success('Image Upload Successfull..', 'title');
        this.GetImg();
      },
      err => {
        error => console.log(err);
      });
  }

  Update(mypath, AboutUsImageId) {
    this.whyus_service.UpdateWhyUsImage(mypath, AboutUsImageId).subscribe(
      (res: any) => {
        this.toastr.success('Image Upload Successfull..', 'title');
        this.GetImg();
      },
      err => {
        error => console.log(err);
      });
  }

  GetImg() {

    this.whyus_service.GetWhyUsImages().subscribe((res: any) => {

      if(res.length > 0){
        this.img_list = res;

        console.log(this.img_list);

      
        this.imgpath =  this.img_list[0].imgPath;
        this.Whyus_imageId =this.img_list[0].whyUs_ImageId;
      }
      

      
    })
  }
}
