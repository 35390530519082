import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ContactusService } from 'src/app/shared/contactus.service';
import { AddContactusComponent } from '../add-contactus/add-contactus.component';

@Component({
  selector: 'app-list-contactus',
  templateUrl: './list-contactus.component.html',
  styleUrls: ['./list-contactus.component.css']
})
export class ListContactusComponent implements OnInit {

  constructor( public service : ContactusService ,
    private toastr: ToastrService,private dialog: MatDialog) { }

  ngOnInit() { 
   

    this.service.GetRecords();
  } 

  editrecords(_cnt: any) {
    this.ShowPopUp();

    
    this.service.form.patchValue({
      contactus_id: _cnt.contactus_id,
      email: _cnt.email,
      address: _cnt.address,
      contact_1: _cnt.contact_1,
      conatct_2: _cnt.conatct_2,
      content:_cnt.content,
      imgPath:_cnt.imgPath,
    })
this.service.imgPath=_cnt.imgPath;


    console.log(_cnt);
    
  }

  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "700px";
    this.dialog.open(AddContactusComponent, diallogConfig)

  }

  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.service.DeleteRecords(id).subscribe(res => {
        this.service.GetRecords();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  }

}
