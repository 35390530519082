import { Component, OnInit } from '@angular/core';
import { MyservicesService } from '../shared/myservices.service';
import { WhyusService } from '../shared/whyus.service';

@Component({
  selector: 'app-whyus',
  templateUrl: './whyus.component.html',
  styleUrls: ['./whyus.component.css']
})
export class WhyusComponent implements OnInit {

  constructor(private service: MyservicesService, public service2: WhyusService) { }

  ngOnInit() {

    this.service.GetAllWhyUsData();

this.getimg();
  }


  public imgpath: string;
  public list = [];

  getimg() {

    this.service2.GetWhyUsImages().subscribe((res: any) => {

      if (res.length > 0) {
        this.list = res;
        this.imgpath = this.list[0].imgPath;


        
      }



    })

  }



}
