import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material'
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AboutusserviceService } from 'src/app/shared/aboutusservice.service';
import { AboutUs } from 'src/app/shared/myweb.modules';
import { AddAboutusComponent } from '../add-aboutus/add-aboutus.component';

@Component({
  selector: 'app-list-aboutus',
  templateUrl: './list-aboutus.component.html',
  styleUrls: ['./list-aboutus.component.css']
})
export class ListAboutusComponent implements OnInit {
  
  constructor( private service: AboutusserviceService,
    private toastr: ToastrService,private dialog: MatDialog) { }

  ngOnInit() {
    this.service.form.reset();
    this.service.GetAboutUsAllData();
  }
 


  
  editrecords(aboutus: any) {

    let id = aboutus.aboutus_Id;
    let contents = aboutus.about_Content;
    let avtive = aboutus.is_Active;
    console.log(aboutus.about_Content);
    this.service.GetAboutUsDataById(id, contents, avtive);
    this.ShowPopUp();
  }

  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "1000px";
    this.dialog.open(AddAboutusComponent, diallogConfig)

  }

  
  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.service.DeleteRecords(id).subscribe(res => {
        this.service.GetAboutUsAllData();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  }
}
