import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WhyusService } from 'src/app/shared/whyus.service';
import { environment } from 'src/environments/environment';
import { Why_Us } from 'src/app/shared/myweb.modules';
import { MatDialog, MatDialogConfig } from '@angular/material'
import { ToastrService } from 'ngx-toastr';
import { AddWhyusComponent } from '../add-whyus/add-whyus.component';
import * as $ from 'jquery'
@Component({
  selector: 'app-list-whyus',
  templateUrl: './list-whyus.component.html',
  styleUrls: ['./list-whyus.component.css']
})
export class ListWhyusComponent implements OnInit {
private readonly ImgPath =environment.imgPath;

public fileToUpload: any;
  constructor( private whyus_service :WhyusService,private dialog: MatDialog, private toastr: ToastrService) { }

  ngOnInit() {

    this.GetAllData();
  }



  GetAllData(){
    this.whyus_service.GetWhyUsAllData()
    
  }


  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "800px";
    diallogConfig.height = "500px";
  
    this.dialog.open(AddWhyusComponent, diallogConfig)

  }

  editrecords(row){
     this.whyus_service.form.patchValue({
      $key: row.whyUs_Id,
      _title: row.title,
      _content: row.content,
      _isActive: row.isActive,
      
    });
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dialog.open(AddWhyusComponent,dialogConfig);
    
  }


  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.whyus_service.DeleteRecords(id).subscribe(res => {
        this.whyus_service.GetWhyUsAllData();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  }
}
