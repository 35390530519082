import { Component, OnInit } from '@angular/core';
import { ResumeService } from 'src/app/shared/resume.service';

@Component({
  selector: 'app-view-resume-details',
  templateUrl: './view-resume-details.component.html',
  styleUrls: ['./view-resume-details.component.css']
})
export class ViewResumeDetailsComponent implements OnInit {

  constructor(public resume_service : ResumeService) { }

  ngOnInit() {

    this.resume_service.GetCountry();
    
  }

}
