import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { TestimonialService } from 'src/app/shared/testimonial.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-testimonial-add',
  templateUrl: './testimonial-add.component.html',
  styleUrls: ['./testimonial-add.component.css']
})
export class TestimonialAddComponent implements OnInit {
  public fileToUpload: any;
  readonly rootURL = environment.rootURL;
   public readonly _imgPath = environment.imgPath;
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();
  constructor(private http: HttpClient, private testimonial_service:TestimonialService,private toastr: ToastrService,private router :Router, public dialogRef: MatDialogRef<TestimonialAddComponent>) { }
  button = 'Submit';
  isLoading = false;
  ngOnInit() {
  }
  imgpath: string;
  // -- Upload File
  public uploadFile = (files) => {


    if (files.length === 0) {
      return;
    }

     this.fileToUpload = <File>files[0];
    console.log(this.fileToUpload);

  }


  onSubmit(){
    this.isLoading = true;
    this.button = 'Processing';

    let idInput = (<HTMLInputElement>document.getElementById('file')).files;

    console.log(idInput.length);

    if(idInput.length===0){
      return this.SaveData(this.testimonial_service.service_imgPath);
    }
    else{

      const formData = new FormData();
      formData.append('file', this.fileToUpload, this.fileToUpload.name);
    this.http.post(this.rootURL + 'uploads/file-upload.php', formData, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response) {
            this.onUploadFinished.emit(event.body);

            this.SaveData(event.body);
          }
        });

    }


  }


  SaveData(imgpath){



    if(this.testimonial_service.form.controls.TestimonialID.value == null){
      console.log('insert');


      this.testimonial_service.SaveRecors(imgpath).subscribe((result) =>{
        if (result > 0){

          this.toastr.success('Record Save Successfully..', 'success..!');
          this.isLoading = false;
          this.button = 'Submit';
         this.onClear();

        }
      })

    }
    else
    {
console.log('update');
this.testimonial_service.UpdateRecords(imgpath).subscribe((result) =>{
  if (result > 0){

    this.toastr.success('Record Updated Successfully..', 'updated..!');
    this.isLoading = false;
          this.button = 'Submit';
   this.onClear();

  }
})



    }

  }


  onClear() {
    this.testimonial_service.form.reset();
    this.dialogRef.close();
   this.testimonial_service.GetRecords();
   this.testimonial_service.service_imgPath='';
  }

}
