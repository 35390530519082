import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AdminServiceService } from 'src/app/shared/admin-service.service';
import { AdminServiceAddComponent } from '../admin-service-add/admin-service-add.component';

@Component({
  selector: 'app-admin-service-list',
  templateUrl: './admin-service-list.component.html',
  styleUrls: ['./admin-service-list.component.css']
})
export class AdminServiceListComponent implements OnInit {

  constructor( private service : AdminServiceService,private dialog: MatDialog,private toastr: ToastrService) { }

  ngOnInit() {

    this.service.Get_service_mastet();
  }

  editrecords(_serv: any) {

    console.log(_serv);
    
    this.ShowPopUp();

    this.service.service_imgPath=_serv.imgPath;
    this.service.form.patchValue({

      ServiceId:_serv.serviceId ,
      ServiceTitle:_serv.serviceTitle ,
      Content:_serv.content
    
    })
  }


  ShowPopUp() {
    const diallogConfig = new MatDialogConfig();
    diallogConfig.disableClose = false;
    diallogConfig.autoFocus = true;
    diallogConfig.width = "700px";
    this.dialog.open(AdminServiceAddComponent, diallogConfig)

  }
  
  Delete_Records(id: number) {
    if (confirm('Are you sure to delete this record?')) {
      this.service.DeleteRecords(id).subscribe(res => {
        this.service.Get_service_mastet();
        this.toastr.warning("Deleted Successfully", "success..!");
      });
    }
  }

}
 